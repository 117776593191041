<template>
  <div>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/rentalSalesList`,
      }"
      :row-clicked="navigateToItem"
    >
      <template #cell(order_no)="{ data: { item } }">
        {{ getValueFromGivenObjectByKey(item, 'order_id', '&mdash;') }}
      </template>
      <template #cell(state)="{ data: { item } }">
        <status-filter-table :item="item" />
      </template>
      <template #cell(to)="{ data: { item } }">
        <div
          class="d-flex align-items-center"
          style="gap: 8px"
        >
          <feather-icon
            :icon="checkIconType(getValueFromGivenObjectByKey(item, 'to.details.status', '&mdash;'))"
            size="18"
          />
          <!--   LTickIcon       -->
          <span>
            {{ getValueFromGivenObjectByKey(item, 'to.cust_name_dba', '&mdash;') }}
          </span>
        </div>
      </template>
      <template #cell(ship_by)="{ data: { item } }">
        {{ item.shipping }}
      </template>
      <template #cell(return_by)="{ data: { item } }">
        {{ item.return }}
      </template>

      <template #manage-monthly-date>
        <manage-monthly-date />
      </template>
      <!--   Table Top Right Side   -->
      <template #status-filter-tab>
        <status-filter-tab @filter-updated="updateQuery" />
      </template>
      <template #filter>
        <status-filter-color-key class="ml-auto" />
      </template>
    </l-table>
  </div>
</template>

<script>
import LTable from '@/views/components/LTable/LTable.vue'
// eslint-disable-next-line import/no-cycle
import { getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import config from '../config'
// Components
import StatusFilterTable from '../components/list/StatusFilterTable.vue'
import StatusFilterColorKey from '../../../components/use-as-global/StatusFilterColorKey.vue'
import ManageMonthlyDate from '../../../components/use-as-global/ManageMonthlyDate.vue'
import StatusFilterTab from '../components/list/StatusFilterTab.vue'

export default {
  name: 'List',
  components: {
    StatusFilterTab,
    LTable,
    StatusFilterTable,
    ManageMonthlyDate,
    StatusFilterColorKey,
  },
  methods: {
    async updateQuery() {
      try {
        await this.$refs.lTableRef.refetchData()
      } catch {
        console.log('errr')
      }
    },
    checkIconType(status) {
      if (status === 1) {
        return 'LTickIcon'
      } if (status === 2) {
        return 'LNoIcon'
      }
      return 'LWarningIcon'
    },
    navigateToItem({ id, state }) {
      if (state === 0) {
        this.$router.push({
          name: 'draft-quote-information',
          params: { id },
        })
      } else if (state === 2) {
        this.$router.push({
          name: 'approve-quote-information',
          params: { id },
        })
      } else if (state === 1) {
        this.$router.push({
          name: 'hold-quote-information',
          params: { id },
        })
      } else if (state === 3) {
        this.$router.push({
          name: 'approve-quote-information',
          params: { id },
        })
      } else {
        this.$router.push({
          name: 'approve-quote-information',
          params: { id },
        })
      }
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()
    return {
      MODULE_NAME,
      tableColumns,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>
