var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.payment_term.rules === _vm.paymentTermsRules.ChargeAtOrderApproval.rules)?_c('div',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},_vm._l((_vm.rentalAndSaleChargeFulfilmentStatus),function(status,idx){return _c('div',{key:idx},[(_vm.item.state === idx)?_c('feather-icon',{class:("" + (_vm.orderStatesAndStatuses.find(function (ref) {
	var status = ref.status;

	return status === _vm.item.status;
}).style.styleName)),attrs:{"icon":status.icon,"size":"18"}}):_c('feather-icon',{class:idx < _vm.item.state ? 'ORDERS_STATUS_READY' : 'ORDERS_STATUS_NO_ACTION',attrs:{"icon":status.icon,"size":"18"}})],1)}),0):_c('div',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},_vm._l((_vm.rentalAndSaleFulfilmentChargeStatus),function(status,idx){return _c('div',{key:idx},[(_vm.item.state === idx)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],class:("" + (_vm.orderStatesAndStatuses.find(function (ref) {
	var status = ref.status;

	return status === _vm.item.status;
}).style.styleName)),attrs:{"title":status.title,"icon":status.icon,"size":"18"}}):_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],class:idx < _vm.item.state ? 'ORDERS_STATUS_READY' : 'ORDERS_STATUS_NO_ACTION',attrs:{"title":status.title,"icon":status.icon,"size":"18"}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }